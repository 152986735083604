<template>
	<div>
		<img src="https://shops.scxby.cn/upload/common/images/customers/1.jpg">
		<img src="https://shops.scxby.cn/upload/common/images/customers/2.jpg">
		<img src="https://shops.scxby.cn/upload/common/images/customers/3.jpg">
		<img src="https://shops.scxby.cn/upload/common/images/customers/4.jpg">
		<img src="https://shops.scxby.cn/upload/common/images/customers/5.jpg" style="cursor: pointer;" @click="shows()">
		<div>
			<div class="forms" v-if="isShow">
				<div class="titles">
					<span style="color:red">欢迎留下</span>
					您的详情资料
					<form>
						<div style="text-align: left;width: 360px;margin: 0 auto;">
							<label>营业执照</label>
							<input type="file"/>
						</div>
						<div style="text-align: left;width: 360px;margin: 0 auto;">
							<label>开户许可证</label>
							<input type="file"/>
						</div>
						<div>
							<input type="text" class="input" placeholder="公司名称"/>
						</div>
						<div>
							<input type="text" class="input" placeholder="联系人"/>
						</div>
						<div>
							<input type="text" class="input" placeholder="联系方式"/>
						</div>
						<div>
							<textarea placeholder="意向合作" style="resize: none;"></textarea> 
						</div>
						<div class="subit">
							<a href="">提交</a>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	
	export default {
	    data: () => {
	        return {
				isShow : false
	        }
	    },
	    created() {
	    },
	    computed: {
	    },
	    methods: {
	        shows() {
				this.isShow=true
				
	        },
	    },
	}
	
</script>

<style lang="scss" scoped>
	.forms{ text-align: center;background: #fff;padding: 20px 0; top:50%;}
	.forms .titles{ font-size: 18px;}
	.forms label{ font-size: 14px;color:rgb(118, 118, 118);width: 90px;display: inline-block}
	.forms .input{ width: 350px;height: 25px;padding-left: 5px;}
	.forms textarea{ width: 350px;height: 50px;padding-left: 5px;}
	.forms form div{ margin: 20px 0;}
	.forms .subit { background: red;width: 100px;margin:0 auto;color:#fff;border-radius: 10px;}
	.forms .subit a{color:#fff;font-size: 14px }
</style>